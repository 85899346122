/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image'
import Card from './card'
import cardsInfos from "./data"
import Col from 'react-bootstrap/Col';




class CarouselSlide extends React.Component{
  render(){
    var items = []

    var test = []
    
    cardsInfos.forEach(cardInfo => {
      const img1 = require('../img/cards/'+cardInfo["image"])
        items.push(
            <Image     
                className="test"
                src={img1}
            />
        )
    });

    for (let i = 0; i < items.length; i+=4) {
        test.push(
          <Carousel.Item>
              {items[i]}
              {items[i+1]}
              {items[i+2]}
              {items[i+3]}
          </Carousel.Item>
        )
    }
    return <div className="container">
    
      <Carousel slide indicators={false} controls={false} interval={3000}>
          {test}
        </Carousel>
      </div>

  }
}

/*
function CarouselFadeExample() {

  var items = []

  var test = []
  
  cardsInfos.forEach(cardInfo => {
    const img1 = require('../img/cards/'+cardInfo["image"])
      items.push(
          <Image     
              className="test"
              src={img1}
          />
      )
  });

  for (let i = 0; i < items.length; i+=4) {
      test.push(
        <Carousel.Item>
            {items[i]}
            {items[i+1]}
            {items[i+2]}
            {items[i+3]}
        </Carousel.Item>
      )
  }
  return <div className="container">
  
    <Carousel slide indicators={false} controls={false} interval={3000}>
        {test}
      </Carousel>
    </div>
  
  
}
*/
export default CarouselSlide;