/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from "react";
import Image from 'react-bootstrap/Image'
import logo from '../img/icons/logo.png'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function myNav() {
  return <Navbar bg="black" expand="lg">
    <Container>
      <div></div>
    </Container>
  </Navbar>
 
}

export default myNav;


/*
 <nav id="top" class="navbar navbar-sm navbar-expand-lg navbar-light bg-light"> 
    <div class="navbar-header">
        <a class="navbar-brand" href="#URL_SITE_SPIP"><img class="img-fluid logo" src="#CHEMIN{img/icons/logo.png}"></a>
    </div>
  </nav>
  */
