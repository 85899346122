
/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import CarouselSlide from "../components/carousel";
import Introduction from "../components/introduction"
import Social from "../components/social"
import Footer from "../components/footer"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import '../css/temporary.css';


const previewlink = {
color:"white",
"text-decoration":"none",
}

const img1 = require('../img/melgraphics/Page_1.jpg')
const img2 = require('../img/melgraphics/altar-title-1.png')
const img3 = require('../img/melgraphics/buy-button-1.png')
const img4 = require('../img/melgraphics/pyramid-1.png')
const img5 = require('../img/melgraphics/left_buy_card.jpg')
const img6 = require('../img/melgraphics/centre_buy_card.jpg')
const img7 = require('../img/melgraphics/right_buy_card.jpg')
const img8 = require('../img/buttons/Rectangle_5.png')
const img9 = require('../img/buttons/Rectangle_20.png')
const img10 = require('../img/buttons/Rectangle_19b.png')

const discord = require('../img/icons/discord3.png')
const facebook = require('../img/icons/facebook.png')
const instagram = require('../img/icons/instagram2.png')
const twitch = require('../img/icons/twitch.png')
const youtube = require('../img/icons/youtube2.png')
const twitter = require('../img/icons/twitter2.png')
const linkedin = require('../img/icons/linkedin.png')

function Home() {
    return (

 <div className="totalwrap">


    <Container className='maincontainer'>

<div className="innerwrap">
      <Row>
        <Col xs={12} md={6} lg={6}>        
          <div>
            <Image className='d-block mx-auto' src={img2}  alt="" width="100%" height="" />
          </div>
        </Col>
        <Col xs={12} md={6} lg={6}>
        <div className="text-center printyourself" >Print it yourself card game </div>
        </Col>
    </Row>


    <Row>
        <Col xs={12} md={12} lg={6}> 
          <Image class="d-block mx-auto" src={img4} alt="" width="100%" height="" />
        </Col>

        <Col xs={12} md={12} lg={6}>

          <div className='comingsoonfluid'>Coming Soon</div>

          <div className='followusfluid'>
            <div className='followusfluid2'>Follow Us</div>
          <div className='followusfluid3'>
            <ul className="social-menu" > 
                        <li><a href="https://discord.gg/2DUYVvT"><i className="fab"><Image style={{"width" : "auto", "height" : "40px"}} src={discord} /></i></a></li>
                        <li><a href="https://www.facebook.com/abalance.ch/"><i className="fab"><Image style={{"width" : "auto", "height" : "40px"}} src={facebook} /></i></a></li>
                        <li><a href="https://www.linkedin.com/company/abalance-sa/"><i className="fab"><Image style={{"width": "auto", "height": "40px"}} src={linkedin} /></i></a></li> 
                        <li><a href="https://twitter.com/Abalance16/"><i className="fab"><Image style={{"width": "auto", "height": "40px"}} src={twitter} /></i></a></li>
                        <li><a href="https://www.instagram.com/abalance_gaming/"><i className="fab"><Image style={{"width" : "auto", "height": "40px"}} src={instagram} /></i></a></li> 
                        <li><a href="https://www.youtube.com/channel/UCrQiCzy-DE86cel3P1VnY-Q/featured"><i className="fab"><Image style={{"width": "auto", "height": "40px"}} src={youtube} /></i></a></li>
          </ul></div>
          </div>

          <div className='previewfluid'> 
           <a href="/cards" style={previewlink} className='previewcardslink'>Preview Cards</a>
          </div>

        </Col>
  </Row>
</div>
  </Container>

<div className='footer1'>
<div className='footer2'>Abalance SA</div>
<div className='footer3'>contact@abalance.ch</div>
</div>

</div>

    );
}

export default Home;
