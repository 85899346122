/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Pulse extends React.Component{

    constructor(props) {    
        super(props)
    }

    render(){
        var valReturn = []


        valReturn.push(
            <Row xs={12} md={12} lg={12}>
                <Col className='justify-content-center' style={{"display":"flex", "text-transform" : "uppercase"}}><h1>{this.props.name}</h1></Col>
            </Row>
        )

        const p = this.props.pulse.sort((a,b) => a.props.title > b.props.title)
        for (let i = 0; i < p.length; i+=6) {
            valReturn.push(
                <Row xs={3} md={6} lg={6}>
                    <Col>{p[i]}</Col>
                    <Col>{p[i+1]}</Col>
                    <Col>{p[i+2]}</Col>
                    <Col>{p[i+3]}</Col>
                    <Col>{p[i+4]}</Col>
                    <Col>{p[i+5]}</Col>
                    
                </Row>
            )
        }

        

        return <Container fluid>
            {valReturn}
        </Container>
    }
   
}

export default Pulse
