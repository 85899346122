
/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import Cards from "../components/cards";


import React from 'react';

function Home() {
    return (
        <Cards />
    );
}

export default Home;