/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from 'react';
import Image from 'react-bootstrap/Image'


class Footer extends React.Component{
    render(){
        return <section class="">
        </section>
    }
}



export default Footer

