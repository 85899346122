/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


const texts = [
    "*Search your pressure for a Chaos card, reveal it and put it into your hand. Shuffle your pressure."
]



/*{
        "enTitle" : "Anagemas",
        "frTitle" : "Anagemas",
        "enText" : " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget augue urna. Maecenas venenatis sed enim ut suscipit. Donec ac orci eu justo finibus bibendum a a urna. Maecenas placerat, neque quis varius mattis, nisi quam pellentesque sem, vel ultricies urna urna a eros. Sed ornare vitae ante elementum bibendum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean ornare ornare tellus ut maximus. Nullam tincidunt nunc sit amet congue finibus. Phasellus pharetra blandit mauris, quis semper lorem maximus eget. Aliquam egestas efficitur eleifend. Phasellus quis tortor massa. Duis eget justo maximus, facilisis ex a, interdum nulla."+
        
        "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla at turpis quis nunc ultrices porta non a enim. Vivamus vel leo sit amet diam semper sollicitudin. Nullam tincidunt, ipsum at sollicitudin vulputate, sapien purus rutrum enim, non finibus purus ipsum at turpis. Pellentesque tincidunt, ex nec ultrices luctus, nisl sapien varius libero, non pulvinar metus quam ut ante. Donec in imperdiet eros. Ut at congue arcu. Nunc sed dignissim eros. Phasellus congue velit ac rutrum posuere. Quisque id facilisis tortor."+
        
        "Vestibulum scelerisque enim nec quam porttitor volutpat. Curabitur quis nisl non leo sodales tempor in eu lacus. Morbi fringilla hendrerit tortor tincidunt feugiat. Donec laoreet porttitor neque, sed venenatis nunc eleifend quis. Vestibulum varius, felis at rutrum auctor, ipsum libero cursus arcu, in fringilla sapien sapien et nisi. Etiam vestibulum molestie diam, sit amet rhoncus nisi vehicula eu. Nulla malesuada at urna eu vestibulum. Morbi quam lorem, accumsan non mauris ac, dignissim aliquam tortor. Proin vitae tempor metus. Cras vestibulum mollis neque, id lobortis lorem dignissim accumsan. Aliquam imperdiet convallis felis non fermentum."+
        
        "Sed in tempus eros. Suspendisse aliquet diam neque, ac hendrerit justo facilisis eu. Fusce nec urna turpis. Nullam mollis tincidunt arcu id pulvinar. Phasellus eget elit accumsan justo malesuada condimentum. Proin viverra scelerisque purus eu vehicula. Sed a fermentum nisl. Nam in risus blandit, viverra tortor id, placerat dolor. Aliquam purus lacus, vulputate vel massa tempus, dignissim feugiat magna. Etiam urna odio, lacinia interdum tristique et, dictum sit amet orci. Suspendisse dignissim vulputate urna vel volutpat. Integer bibendum turpis in leo viverra porta. Proin id ipsum convallis, pretium dui vitae, malesuada nulla. Duis efficitur, ante eget facilisis iaculis, nunc justo scelerisque sem, et sodales sapien quam id ipsum. Etiam ac lorem nibh. Nunc sagittis tortor aliquam turpis tincidunt egestas."+
        
        "Integer quis lacus quam. Etiam sed augue eu metus dapibus eleifend vitae non leo. Curabitur pretium elit vel dui pulvinar, quis lobortis nisl efficitur. Curabitur tincidunt urna at lacus porta, tristique blandit felis sagittis. Nam in nisi dictum ipsum luctus vestibulum. Donec dui urna, posuere in dolor sed, pretium iaculis orci. Praesent non massa nec ipsum commodo tristique blandit eget tellus. Cras semper sem ac enim mollis tempus. ",
        "frText" : "",
        "image" : "petrified_potential-1.png",
        "pulse": "truth"
    
    },

    
  


   

*/

const template = {
    "enTitle" : "",
    "frTitle" : "",
    "enText" : "",
    "frText" : "",
    "image" : "",
    "pulse": "order",
    "hasChange": false
}

const cardsInfos = [
    // TRUTH
        {
            "enTitle" : "Anagemas",
            "frTitle" : "Anagemas",
            "enText" : "",
            "frText" : "",
            "image" : "anagemas.png",
            "pulse": "truth"
        
        },{
            "enTitle" : "Baragemas",
            "frTitle" : "Baragemas",
            "enText" : "",
            "frText" : "",
            "image" : "baragemas.png",
            "pulse": "truth"
        
        },{
            "enTitle" : "Petrified Potential",
            "frTitle" : "Potentiel pétrifié",
            "enText" : "Whenever another card is returned to your hand from the field, Petrified Potential gets +1 on its sides until end of turn.",
            "frText" : "A chaque fois qu'une autre carte est renvoyée dans votre main depuis le terrain, le Potentiel pétrifié gagne +1 sur ses côtés jusqu'à la fin du tour.",
            "image" : "petrified_potential.png",
            "pulse": "truth"
        },{
            "enTitle" : "Floating Potential",
            "frTitle" : "Potentiel volant/ailé",
            "enText" : "Whenever another card enters the field under your control, Floating Potential gets +1 on its sides until end of turn.",
            "frText" : "A chaque fois qu'une autre carte entre sur le terrain sous votre contrôle, le Potentiel volant/ailé gagne +1 sur ses côtés jusqu'à la fin du tour.",
            "image" : "floating_potential.png",
            "pulse": "truth"
        
        },{
            "enTitle" : "Effigy of the Stone Gaze",
            "frTitle" : " Effigie du regard de pierre",
            "enText" : "*Return two cards you control to their owner's hand: Put target card your opponent controls on top of its owner's deck.",
            "frText" : "*Renvoyez deux cartes que vous contrôlez dans la main de leur propriétaire : Mettez la carte ciblée contrôlée par votre adversaire au-dessus du deck de son propriétaire.",
            "image" : "effigy_of_the_stone_gaze.png",
            "pulse": "truth"
        },{
            "enTitle" : "Humble Ezequeel",
            "frTitle" : "Humble ézequiel",
            "enText" : "When Humble Ezequeel enters the field, you may return another card you control to it's owner's hand. If you do, draw a card.",
            "frText" : "Quand l'Humble ézequiel entre sur le terrain, vous pouvez renvoyer une autre carte que vous contrôlez dans la main de son propriétaire. Si vous faites ainsi, piochez une carte.",
            "image" : "humble_ezequeel.png",
            "pulse": "truth"  
        },{
            "enTitle" : "Keeper of Batariel",
            "frTitle" : "Gardien de Batariel",
            "enText" : "*Target card you control can't be the target of effects or activations your opponent controls until your next opening phase.",
            "frText" : "*Une carte ciblée que vous contrôlez ne peut pas être la cible d'effets ou d'activations contrôlés par votre adversaire jusqu'à votre prochaine phase d'ouverture. ",
            "image" : "keeper_of_batariel.png",
            "pulse": "truth"
        },{
            "enTitle" : "Living Monument",
            "frTitle" : "Monument vivant",
            "enText" : "*Cards you control gain +1 on their sides until your next opening phase.",
            "frText" : "*Les cartes que vous contrôlez gagnent +1 sur leurs côtés jusqu'à votre prochaine phase d'ouverture.",
            "image" : "living_monument.png",
            "pulse": "truth"
        },{
            "enTitle" : "Jomjael, Awaker of Potential",
            "frTitle" : "Jomjael, éveilleur de potentiel",
            "enText" : "*Return another card you control to its owner's hand: Another card you control can be activated an additional time this turn and gains +2 on its sides until your next opening phase.",
            "frText" : "*Renvoyez une autre carte que vous contrôlez dans la main de son propriétaire : Une autre carte que vous contrôlez peut être activée une fois de plus ce tour-ci et gagne +2 sur ses côtés jusqu'à votre prochaine phase d'ouverture.",
            "image" : "jomjael_awaker_of_potential.png",
            "pulse": "truth"
        },{
            "enTitle" : "Danegemas",
            "frTitle" : "Danegemas",
            "enText" : "",
            "frText" : "",
            "image" : "danegemas.png",
            "pulse": "truth"
        },{
            "enTitle" : "Keeper of True Potential",
            "frTitle" : "Gardien du vrai potentiel",
            "enText" : "Whenever another card enters the field under your control or another card is returned from the field to your hand, Keeper of True Potential gets +1 on its sides until end of turn.",
            "frText" : "À chaque fois qu'une autre carte entre sur le terrain sous votre contrôle ou est renvoyée dans votre main depuis le terrain, le Gardien du vrai potentiel gagne +1 sur ses côtés jusqu'à votre prochaine phase d'ouverture.",
            "image" : "keeper_of_true_potential.png",
            "pulse": "truth"
        },{
            "enTitle" : "Emissary of Hayaliel",
            "frTitle" : "Emissaire d'Hayaliel",
            "enText" : "When Emissary of Hayaliel is returned to your hand from the field, you may banish it. If you do, draw two cards.",
            "frText" : "Quand l'Emissaire d'Hayaliel est renvoyé dans votre main depuis le terrain, vous pouvez l'exiler. Si vous faites ainsi, priochez deux cartes.",
            "image" : "emissary_of_hayaliel.png",
            "pulse": "truth"
        },{
            "enTitle" : "Spawn of Mastogemas ",
            "frTitle" : "Engeance de Mastogemas",
            "enText" : "*Target card you control can't be destroyed until your next opening phase.",
            "frText" : "*Une carte ciblée que vous contrôlez ne peut pas être détruite jusqu'à votre prochaine phase d'ouverture.",
            "image" : "spawn_of_mastogemas.png",
            "pulse": "truth"
        },{
            "enTitle" : "Hayaliel, the Tactician",
            "frTitle" : "Hayaliel, le tacticien",
            "enText" : "Your opponent plays with their hand revealed. *Choose another card you control and a card in your hand: Exchange them, then repeat this process for your opponent.",
            "frText" : "Votre adversaire joue avec sa main révélée. *Choisissez une autre carte que vous contrôlez et une carte dans votre main : Échangez-le, puis répétez ce processus avec votre adversaire.",
            "image" : "hayaliel_the_tactician.png",
            "pulse": "truth"
        },{
            "enTitle" : "Samiazemas",
            "frTitle" : "Samiazemas",
            "enText" : "",
            "frText" : "",
            "image" : "samiazemas.png",
            "pulse": "truth"
        
        },{
            "enTitle" : "Kokabile, Mentor of the Hidden Path",
            "frTitle" : "Kokabile, mentor de la voie cachée",
            "enText" : "*Return a card you control and a card your opponent controls to their owner's hand: Activate another card you control.",
            "frText" : "*Renvoyez une carte que vous contrôlez et une carte contrôlée par votre adversaire dans la main de leur propriétaire : Activez une autre carte que vous contrôlez.",
            "image" : "kokabile_mentor_of_the_hidden_path.png",
            "pulse": "truth"
        },{
            "enTitle" : "Emissary of Ophaniel",
            "frTitle" : "Emissaire d'Ophaniel",
            "enText" : "When Emissary of Ophaniel is returned to your hand from the field, you may banish it. If you do, put up to two Truth cards from your hand onto the field.",
            "frText" : "Quand l'Emissaire d'Ophaniel est renvoyé dans votre main depuis le terrain, vous pouvez l'exiler. Si vous faites ainsi, mettez sur le terrain jusqu'à deux cartes Vérité de votre main.",
            "image" : "emissary_of_ophaniel.png",
            "pulse": "truth"
        },{
            "enTitle" : "Nagasargiel, the Master",
            "frTitle" : "Nagarsargiel, le Maître",
            "enText" : "Whenever a card is returned to your hand from the field, Nagarsargiel can be activated an additional time this turn. *Discard a card: Negate target shield.",
            "frText" : "À chaque fois qu'une carte est renvoyée dans votre main depuis le terrain, Nagarsagiel peut être activé une fois de plus ce tour-ci. *Défaussez-vous d'une carte : Annuler le shield ciblé.",
            "image" : "nagasargiel_the_master.png",
            "pulse": "truth"
        },{
            "enTitle" : "Requiel, the Stone Gaze",
            "frTitle" : "Requiel, le regard de pierre",
            "enText" : "*Return all cards on the field to their owner's hand.",
            "frText" : "*Renvoyez toutes les cartes sur le terrain dans la main de leur propriétaire.",
            "image" : "requiel_the_stone_gaze.png",
            "pulse": "truth"
        },{
            "enTitle" : "Mastogemas",
            "frTitle" : "Mastogemas",
            "enText" : "",
            "frText" : "",
            "image" : "mastogemas.png",
            "pulse": "truth"
        },
    // TRUTH
    
    // CHAOS
        {
            "enTitle" : "Asmodee",
            "frTitle" : "Asmodee",
            "enText" : "",
            "frText" : "",
            "image" : "asmodee.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Baalee",
            "frTitle" : "Baalee",
            "enText" : "",
            "frText" : "",
            "image" : "baalee.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Alastoree",
            "frTitle" : "Alastoree",
            "enText" : "",
            "frText" : "",
            "image" : "alastoree.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Azazelee",
            "frTitle" : "Azazelee",
            "enText" : "",
            "frText" : "",
            "image" : "azazelee.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Carrier of Barbatos",
            "frTitle" : "ransporteur de Barbatos",
            "enText" : "*Search your pressure for up to three Chaos cards, reveal them, put one of them in your hand and the rest on top of your deck in any order. Shuffle your pressure.",
            "frText" : "*Cherchez jusqu'à trois cartes Chaos dans votre pression, révélez-les, mettez en une dans votre main et le reste au-dessus de votre deck dans n'importe quel ordre. Mélangez votre pression.",
            "image" : "carrier_of_barbatos.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Carrier of Behemoth",
            "frTitle" : "Transporteur de Béhémoth",
            "enText" : "*Search your pressure for a Chaos card and put it onto the field. Destroy that card at the end of your turn. Shuffle your pressure.",
            "frText" : "*Cherchez une carte Chaos dans votre pression et mettez-la sur le terrain. Détruisez cette carte à la fin du tour. Mélangez votre pression.",
            "image" : "carrier_of_behemoth.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Bilazazelee",
            "frTitle" : "Bilazazelee",
            "enText" : "",
            "frText" : "",
            "image" : "bilazazelee.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Belphegor, the Endless Torments",
            "frTitle" : "Belphegor, les tourments infinis",
            "enText" : "Whenever you get pressured during your turn, your opponent pressures themselves by the same amount.",
            "frText" : "A chaque fois que vous subissez des points de pression durant votre tour, votre adversaire subit autant de points de pression.",
            "image" : "belphegor_the_endless_torments.png",
            "pulse": "chaos",
            "hasChange": false
        },{
            "enTitle" : "Disturbing Crux",
            "frTitle" : "Crux inquiétant/troublant",
            "enText" : "*Each player pressures themselves by 2.",
            "frText" : "*Chaque joueur subit 2 points de pression.",
            "image" : "disturbing_crux.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Flying Carnival",
            "frTitle" : "Carnaval volant",
            "enText" : "Whenever Flying Carnival gets activated, you may search your pressure for a card and put it into your graveyard. If you do, target player gets pressured by that card's intensity, then shuffle you pressure.",
            "frText" : "A chaque fois que le Carnaval volant est activé, vous pouvez chercher une carte dans votre pression et la mettre dans votre pression. Si vous faites ainsi, le joueur ciblé subit autant de points de pression que l'intensité de cette carte.",
            "image" : "flying_carnival.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Warmachine Balam",
            "frTitle" : "Machine de guerre Balam",
            "enText" : "Whenever Warmachine Balam gets activated, each player pressures themselves by 2.",
            "frText" : "A chaque fois que la Machine de guerre Balam est activée, chaque joueur subit 2 points de pression.",
            "image" : "warmachine_balam.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Effigy of Discomfort",
            "frTitle" : "Effigie d'inconfort",
            "enText" : "*Each player pressures themselves by 3.",
            "frText" : "*Chaque joueur subit 3 points de pression.",
            "image" : "effigy_of_discomfort.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Dual Piercers",
            "frTitle" : "Transperceurs doubles",
            "enText" : "*Pressure yourself by 2: Target card ignores shield and can attack up to two targets at once until end of turn.",
            "frText" : "*Subissez 2 points de pression : La carte ciblée ignore le shield et peut attaquer jusqu'à deux cibles en même temps.",
            "image" : "dual_piercers.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Soul Harvester",
            "frTitle" : "Moissonneur d'âmes",
            "enText" : "*Pressure yourself by 1: Each player sacrifices a card they control.",
            "frText" : "*Subissez 1 point de pression : Chaque joueur sacrifie une carte qu'il contrôle.",
            "image" : "soul_harvester.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Knight made of Pain",
            "frTitle" : "Chevalier fait de douleur",
            "enText" : "*Each player pressures themselves by 4.",
            "frText" : "*Chaque joueur subit 4 points de pression.",
            "image" : "knight_made_of_pain.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Carrier of Bile",
            "frTitle" : "Transporteur de fiel",
            "enText" : "*Search your pressure for a Chaos card, reveal it and put it into your hand. Shuffle your pressure.",
            "frText" : "*Chercher une carte Chaos dans votre pression, révélez-la et mettez-la dans votre main. Mélangez votre pression.",
            "image" : "carrier_of_bile.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Warmachine Bahamut",
            "frTitle" : "Machine de guerre Bahamut",
            "enText" : "When Warmachine Bahamut is put into your graveyard from anywhere, each player pressures themselves by 5.",
            "frText" : "Quand la Machine de guerre Bahamut est mise dans votre cimetière depuis n'importe où, chaque joueur subit 5 points de pression.",
            "image" : "warmachine_bahamut.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Curson of Randomized Arsenal",
            "frTitle" : "Curson d'arsenal aléatoire",
            "enText" : "Whenever a card gets activated, its controller pressures themselves by that card's intensity.",
            "frText" : "A chaque fois qu'une carte est activée, son contrôleur subit autant de points de pression que l'intensité de cette carte.",
            "image" : "curson_randomized_arsenal.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Dagon, Primal Pulse Heterodine",
            "frTitle" : "Dagon, hétérodine de pulse primaire",
            "enText" : "Whenever you get pressured for the first time during your turn, you may put a Chaos card from your hand onto the field.",
            "frText" : "A chaque fois que vous subissez des points de pression pour la première fois durant votre tour, vous pouvez mettre sur le terrain une carte Chaos de votre main.",
            "image" : "dagon_primal_pulse_heterodine.png",
            "pulse": "chaos"
        },{
            "enTitle" : "Bélial, the Mayhem Arsenal",
            "frTitle" : "Bélial, l'arsenal du Désordre",
            "enText" : "*Sacrifice any number of other cards you control: Each player pressures themselves by the total intensity of the cards sacrificed this way.",
            "frText" : "*Sacrifiez n'importe quel nombre d'autres cartes que vous contrôlez : Chaque joueur subit autant de points de pression que l'intensité totale des cartes sacrifiées ainsi.",
            "image" : "belial_the_mayhem_arsenal.png",
            "pulse": "chaos"
        },
    
    // CHAOS
    
    // DEATH
        {
            "enTitle" : "Bone Collector",
            "frTitle" : "Collectionneur d'ossements",
            "enText" : "",
            "frText" : "",
            "image" : "bone_collector.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Nicor",
            "frTitle" : "Nicor",
            "enText" : "",
            "frText" : "",
            "image" : "nicor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Sinister Eye",
            "frTitle" : "Oeil sinistre",
            "enText" : "*Target card your opponent controls gets -1 on its sides until your next opening phase.",
            "frText" : "*La carte ciblée que votre adversaire contrôle gagne -1 sur ses côtés jusqu'à votre prochaine phase d'ouverture.",
            "image" : "sinister_eye.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Sinister Musicien",
            "frTitle" : "Musicien sinistre",
            "enText" : "*Each player mills a card.",
            "frText" : "*Chaque joueur meule une carte.",
            "image" : "sinister_musician.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Memento Mori",
            "frTitle" : "Memento mori",
            "enText" : "*Choose target card on the field. Destroy it at the beginning of your next opening phase.",
            "frText" : "*Choisissez une carte ciblée sur le terrain. Détruisez-la au début de votre prochaine phase d'ouverture.",
            "image" : "memento_mori.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Plague Doctor",
            "frTitle" : " Docteur de la peste",
            "enText" : "*Discard up to two cards, then draw that many cards.",
            "frText" : "*Défaussez-vous de jusqu'à deux cartes, puis piochez autant de cartes.",
            "image" : "plague_doctor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Raamnor",
            "frTitle" : "Raamnor",
            "enText" : "",
            "frText" : "",
            "image" : "raamnor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Grave Fly",
            "frTitle" : "Mouche nécrophage",
            "enText" : "*Your opponent gets pressured by the number of cards in your graveyard.",
            "frText" : "*Votre adversaire subit autant de points de pression que de cartes dans votre cimetière.",
            "image" : "grave_fly.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Famine Scarab",
            "frTitle" : "Scarabée de la famine",
            "enText" : "*Each player places the top three cards of their pressure into their graveyard.",
            "frText" : "*Chaque joueur place les trois cartes du dessus de sa pression dans son cimetière.",
            "image" : "famine_scarab.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Death Trader",
            "frTitle" : " Négociant en mort",
            "enText" : "*Choose another card you control and a card in your graveyard, then exchange them.",
            "frText" : "*Choisissez une autre carte que vous contrôlez et une carte dans votre cimetière, puis échangez-les.",
            "image" : "death_trader.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Verinor",
            "frTitle" : "Verinor",
            "enText" : "",
            "frText" : "",
            "image" : "verinor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "The Reanimator",
            "frTitle" : "Le Réanimateur",
            "enText" : "*Return target card from your graveyard to the field.",
            "frText" : "*Renvoyez une carte ciblée depuis votre cimetière sur le terrain.",
            "image" : "the_reanimator.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Eye of Decay",
            "frTitle" : " Oeil de décomposition",
            "enText" : "*Target card your opponent controls gets -2 on its sides until your next opening phase.",
            "frText" : "*La carte ciblée que votre adversaire contrôle gagne -2 sur ses côtés jusqu'à votre prochaine phase d'ouverture.",
            "image" : "eye_of_decay.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Malebolgian Singer",
            "frTitle" : "Chanteur malebolgien",
            "enText" : "*Each player mills three cards.",
            "frText" : "*Chaque joueur meule trois cartes.",
            "image" : "malebolgian_singer.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Zephor",
            "frTitle" : "Zephor",
            "enText" : "",
            "frText" : "",
            "image" : "zephor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Nadarr, Lord of Decay",
            "frTitle" : "Nadarr, Seigneur de la décomposition",
            "enText" : "Cards your opponent controls get -1 on their sides.",
            "frText" : "Les cartes que votre adversaire contrôle gagnent -1 sur leurs côtés.",
            "image" : "nadarr_lord_of_decay.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Ea, Saint of the Dead",
            "frTitle" : "Ea, saint patron des morts",
            "enText" : "*Each player sacrifices a card they control of intensity 1. If they can't, they get pressured by 2. Repeat this process for intensities 2, 3, 4 and 5.",
            "frText" : "*Chaque joueur sacrifie une carte qu'il contrôle d'intensité 1. S'il ne le peut pas, il subit 2 points de pression. Répétez ce processus pour les intensités 2,3,4 et 5.",
            "image" : "ea_saint_of_the_dead.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Death Center",
            "frTitle" : "Centre de la mort",
            "enText" : "You may play cards from your graveyard as though they were in your hand (they can't fuel your Altar).",
            "frText" : "Vous pouvez joueur les cartes depuis votre cimetière comme si elles étaient dans votre main (elles ne peuvent pas alimenter votre Altar).",
            "image" : "death_center.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Gheshnor",
            "frTitle" : "Gheshnor",
            "enText" : "",
            "frText" : "",
            "image" : "gheshnor.png",
            "pulse": "death",
            "hasChange": false
        },{
            "enTitle" : "Scarlet Court Assassin",
            "frTitle" : "Assassin de la Cour écarlate",
            "enText" : "*Destroy target card.",
            "frText" : "*Détruisez une carte ciblée.",
            "image" : "scarlet_court_assassin.png",
            "pulse": "death",
            "hasChange": false
        },
    
    // DEATH
    
    // LIFE
        {
            "enTitle" : "Forest Walker",
            "frTitle" : "Marcheur sylvestre",
            "enText" : "*Draw a card.",
            "frText" : "*Piochez une carte.",
            "image" : "forest_walker.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Effigy of Life",
            "frTitle" : "Effigie de la Vie",
            "enText" : "*Open your Altar. The next time you play a card this turn, you get pressured by that card's intensity.",
            "frText" : "*Ouvrez votre Altar. La prochaine fois que vous jouez une carte ce tour-ci, vous subissez autant de points de pression que son intensité.",
            "image" : "effigy_of_life.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Spirit Caller",
            "frTitle" : " Meneur d'esprits/spirituel",
            "enText" : "*Look at the next card you would draw. You may reveal it, open your Altar and put it on top of it. If you don't, draw a card.",
            "frText" : "*Regardez la prochaine carte que vous devriez piocher. Vous pouvez la révéler, ouvrir votre Altar et la placer dessus. Si vous ne le faites pas, piochez une carte.",
            "image" : "spirit_caller.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Qualen",
            "frTitle" : "Qualen",
            "enText" : "",
            "frText" : "",
            "image" : "qualen.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Trabird",
            "frTitle" : "Trapace",
            "enText" : "Whenever Trabird pressures your opponent, put that many cards from the top of your pressure on top of your deck.",
            "frText" : "A chaque fois que le Trapace inflige des points de pression à votre adversaire, mettez autant de cartes depuis le dessus de votre pression sur le dessus de votre deck.",
            "image" : "trabird.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Emissary of Peace",
            "frTitle" : "Emissaire de la Paix",
            "enText" : "*Negate all attacks from target card until your next opening phase.",
            "frText" : "*Annulez toutes les attaques d'une carte ciblée jusqu'à votre prochaine phase d'ouverture.",
            "image" : "emissary_of_peace.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Umpen",
            "frTitle" : "Umpen",
            "enText" : "",
            "frText" : "",
            "image" : "umpen.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Force of Wildness",
            "frTitle" : "Force de sauvagerie",
            "enText" : "While attacking, Force of Wildness gets +1 on its sides for each card in your Altar.",
            "frText" : "Tant qu'elle attaque, la Force de sauvagerie gagne +1 sur ses côtés pour chaque carte dans votre Altar.",
            "image" : "force_of_wildness.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Druid of the Tribe",
            "frTitle" : "  Druide de la tribu",
            "enText" : "*Put a number of cards equal to your Altar intensity from the top of your pressure on top of your deck.",
            "frText" : "*Mettez un nombre de cartes égal à l'intensité de votre Altar depuis le dessus de votre pression sur le dessus de votre deck.",
            "image" : "druid_of_the_tribe.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Fire Dancer",
            "frTitle" : "Danceur du feu",
            "enText" : "Whenever your opponent fuels their Altar from anywhere, they get pressured by their Altar intensity.",
            "frText" : "A chaque fois que votre adversaire alimente son Altar depuis n'importe où, il subit autant de points de pression que l'intensité de son Altar.",
            "image" : "fire_dancer.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Paggen",
            "frTitle" : "Paggen",
            "enText" : "",
            "frText" : "",
            "image" : "paggen.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Peace Speaker",
            "frTitle" : "Oratrice de la Paix",
            "enText" : "*Each pressure you would get is reduced to 1 until your next opening phase.",
            "frText" : "*Tous les points de pression que vous devriez subir sont réduits à 1 jusqu'à votre prochaine phase d'ouverture.",
            "image" : "peace_speaker.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Dalaben",
            "frTitle" : "Dalaben",
            "enText" : "",
            "frText" : "",
            "image" : "dalaben.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Seebo, Avatar of Life",
            "frTitle" : "Seebo, avatar de la Vie",
            "enText" : "*You may put a card from your hand onto the field.",
            "frText" : "*Vous pouvez mettre sur le terrain une carte de votre main.",
            "image" : "seebo_avatar_of_life.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Living Mountain",
            "frTitle" : "Montagne vivante",
            "enText" : "Whenever you fuel your Altar from the field, target player gets pressured by your Altar intensity.",
            "frText" : "A chaque fois que vous alimentez votre Altar depuis le terrain, le joueur ciblé subit autant de points de pression que l'intensité de votre Altar.",
            "image" : "living_mountain.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Embodiment of Nature",
            "frTitle" : "Incarnation de la Nature",
            "enText" : "*Other cards you control can't be destroyed in combat until your next opening phase.",
            "frText" : "*Les autres cartes que vous contrôlez ne peuvent pas être détruites en combat jusqu'à votre prochaine phase d'ouverture.",
            "image" : "embodiment_of_nature.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Caerdonel, Roots of Creation",
            "frTitle" : "Caerdonel, racines de la Création",
            "enText" : "This card can be used to represent any intensity when fuelling your Altar from your hand. It keeps the chosen intensity until it leaves your Altar.",
            "frText" : "Cette carte peut représenter n'importe quelle intensité lorsque vous l'utilisez pour alimenter votre Altar depuis votre main. Elle conserve l'intensité choisie jusqu'à ce qu'elle quitte votre Altar.",
            "image" : "caerdonel_roots_of_creation.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Abalaben",
            "frTitle" : "Abalaben",
            "enText" : "",
            "frText" : "",
            "image" : "abalaben.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Alchemist of the Elements",
            "frTitle" : "Alchimiste des éléments",
            "enText" : "*Exchange the top card of your Altar with another card you control on the field, then open your Altar.",
            "frText" : "*Echangez la carte du dessus de votre Altar avec une autre carte que vous contrôlez, puis ouvrez votre Altar.",
            "image" : "alchemist_of_the_elements.png",
            "pulse": "life",
            "hasChange": false
        },{
            "enTitle" : "Blobird",
            "frTitle" : "Bloriot",
            "enText" : "Whenever Blobird pressures your opponent, put that many cards from the top of your pressure on top of your deck.",
            "frText" : "A chaque fois que le Bloriot inflige des points de pression à votre adversaire, mettez autant de cartes depuis le dessus de votre pression sur le dessus de votre deck.",
            "image" : "blobird.png",
            "pulse": "life",
            "hasChange": false
        },
    // LIFE
    
    //LIES
        {
            "enTitle" : "Dream Trapper",
            "frTitle" : "Trappeur de rêves",
            "enText" : "*Once during your opponent's next turn, whenever a card attacks, you may give it -3 to all sides until you next opening phase.",
            "frText" : "*Une fois durant le prochain tour de votre adversaire, à chaque fois qu'une carte attaque, vous pouvez faire qu'elle gagne -3 sur chaque côté jusqu'à votre prochaine phase d'ouverture",
            "image" : "dream_trapper.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Mind Howler",
            "frTitle" : "Hurleur d'esprit",
            "enText" : "*Return another card you control to its owner's hand: look at your opponent's hand and choose a card of intensity less or equal to that of the returned card. Your opponent discards this card.",
            "frText" : "*Renvoyez une autre carte que vous contrôlez dans la main de son propriétaire : regardez la main de votre advsersaire et choisissez une carte d'intensité inférieure ou égale à celle de la carte renvoyée. Votre adversaire se défausse de cette carte.",
            "image" : "mind_howler.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Manmni",
            "frTitle" : "Manmni",
            "enText" : "",
            "frText" : "",
            "image" : "mamni.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Faceless Eater",
            "frTitle" : "Dévoreur sans visage",
            "enText" : "You may discard Faceless Eater during your main phase. If you do, your opponent discards a card at random.",
            "frText" : "Vous pouvez défausser le Dévoreur sans visage durant votre phase principale. Si vous faites ainsi, votre adversaire se défausse d'une carte au hasard.",
            "image" : "faceless_eater.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Faceless Observer",
            "frTitle" : "Observateur sans visage",
            "enText" : "You may discard Faceless Observer during your main phase. If you do, draw a card.",
            "frText" : "Vous pouvez défausser l'Observateur sans visage durant votre phase principale. Si vous faites ainsi, piochez une carte.",
            "image" : "faceless_observer.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Haunting Shadow",
            "frTitle" : "Ombre obsédante",
            "enText" : "*Your opponent skips their draw during their next opening phase.",
            "frText" : "*Votre adversaire passe sa pioche durant sa prochaine phase d'ouverture.",
            "image" : "haunting_shadow.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Kali",
            "frTitle" : "Kali",
            "enText" : "",
            "frText" : "",
            "image" : "kali.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Poltergeist of Caim",
            "frTitle" : "Esprit frappeur de Caim",
            "enText" : "*Once during your opponent's next turn, after they play a card, you may discard a card. If you do, banish the played card.",
            "frText" : "*Une fois durant le prochain tour de votre adversaire, après qu'une carte est jouée, vous pouvez vous défausser d'une carte. Si vous faites ainsi, exilez la carte jouée.",
            "image" : "poltergeist_of_caim.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Smoke Manifestation",
            "frTitle" : "Manifestation de fumée",
            "enText" : "*Once during your opponent's next turn, whenever they activate a card, you may negate that activation.",
            "frText" : "*Une fois durant le prochain tour de votre adversaire, à chaque fois qu'une carte est activée, vous pouvez annulez cette activation.",
            "image" : "smoke_manifestation.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Hayaliel, the Inquisitor",
            "frTitle" : "Hayaliel, l'Inquisiteur",
            "enText" : "Your opponent plays with their hand revealed. *Choose an intensity value. Your opponent can't play cards of the chosen intensity during their next turn.",
            "frText" : "Votre adversaire joue avec sa main révélée. *Choisissez une intensité. Votre adversaire ne peut pas jouer de cartes d'intensité égale à la valeur choisie durant son prochain tour.",
            "image" : "hayaliel_the_inquisitor.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Psychic Worm",
            "frTitle" : "Vermine psychique",
            "enText" : "*At the beginning of your opponent's next main phase, you may activate one of their cards and choose the targets for the activation (this counts as the card's activation for this turn).",
            "frText" : "*Au début de la prochaine phase principale de votre adversaire, vous pouvez activer une de ses cartes et choisir les cibles pour l'activation (ceci compte comme l'activation de la carte pour ce tour).",
            "image" : "psychic_worm.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Twin Ghosts",
            "frTitle" : "Fantômes jumeaux",
            "enText" : "*Return target card to its owner's hand.",
            "frText" : "*Renvoyez la carte ciblée dans la main de son propriétaire.",
            "image" : "twin_ghost.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Laughing Spirit",
            "frTitle" : "Esprit ricannant",
            "enText" : "*You may discard any number of cards. If you do, draw that many cards plus one.",
            "frText" : "*Vous pouvez vous défaussez de n'importe quel nombre de cartes. Si vous faites ainsi, piochez autant de cartes plus une.",
            "image" : "laughing_spirit.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Pyrazi",
            "frTitle" : "Pyrazi",
            "enText" : "",
            "frText" : "",
            "image" : "pyrazi.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Oriax, Spell Absorber",
            "frTitle" : "Oriax, absorbeur de sorts",
            "enText" : "*Until your next opening phase, Oriax, Spells Absorber copies target card’s text box. If the chosen card has one or more abilities, trigger them and resolve them in any order.",
            "frText" : "*Jusqu'à votre prochaine phase d'ouverture, Oriax, absorbeur de sorts copie le texte de règles d'une carte ciblée. Si la carte choisie a une ou plusieurs capacités, déclenchez-les et résolvez-les dans l'ordre de votre choix.",
            "image" : "oriax_spell_absorber.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Uzzazi",
            "frTitle" : "Uzzazi",
            "enText" : "",
            "frText" : "",
            "image" : "uzzazi.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Judge of All Magic",
            "frTitle" : " Juge omni-magie",
            "enText" : "Whenever you opponent activates a card, you may discard two cards. If you do, negate that activation. Whenever you opponent fuels their Altar, you may discard two cards. If you do, close your opponent's Altar.",
            "frText" : "A chaque fois que votre adversaire active une carte, vous pouvez vous défaussez de deux cartes. Si vous faites ainsi, annulez cette activation. A chaque fois que votre adversaire alimente son Altar, vous pouvez vous défaussez de deux cartes. Si vous faites ainsi, fermez l'Altar de votre adversaire.",
            "image" : "judge_of_all_magic.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Abadi",
            "frTitle" : "Abadi",
            "enText" : "",
            "frText" : "",
            "image" : "abadi.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Caim, Spell Trickster",
            "frTitle" : "Caim, escroc de sorts",
            "enText" : "*Once during your opponent's next turn, whenever they resolve an ability, you may copy that ability and choose new targets for the copy.",
            "frText" : "*Une fois durant le prochain tour de votre adversaire, à chaque fois qu'une capacité se résout, vous pouvez copier cette capacité et choisir de nouvelles cibles pour la copie.",
            "image" : "caim_spell_trickster.png",
            "pulse": "lies",
            "hasChange": false
        },{
            "enTitle" : "Angel of Calumny",
            "frTitle" : "Ange de la Calomnie",
            "enText" : "Whenever a player discards a card, banish the top two cards of your opponent's deck.",
            "frText" : "A chaque fois qu'un joueur se défausse d'une carte, exilez les deux cartes du dessus du deck de votre adversaire.",
            "image" : "angel_of_calumny.png",
            "pulse": "lies",
            "hasChange": false
        },
    // LIES
    
    // ORDER
        {
            "enTitle" : "Niolochoid",
            "frTitle" : "Niolochoid",
            "enText" : "*Banish another target card until Niolochoid leaves the field.",
            "frText" : "*Exilez une autre carte ciblée jusqu'à ce que Niolochoid quitte le terrain.",
            "image" : "niolochoid.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Micomona",
            "frTitle" : "Micomona",
            "enText" : "",
            "frText" : "",
            "image" : "micomona.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Naamahoid",
            "frTitle" : "Naamahoid",
            "enText" : "*Negate target card's activations until your next opening phase.",
            "frText" : "*Annulez les activations de la carte ciblée jusqu'à votre prochaine phase d'ouverture.",
            "image" : "naamahoid.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Niasahoid",
            "frTitle" : "Niasahoid",
            "enText" : "Cards shielded by Niasahoid can't be the target of effects or activations your opponent controls.",
            "frText" : "Les cartes protégées par Niasahoid ne peuvent pas être la cible d'effets ou d'activations contrôlés par votre adversaire. ",
            "image" : "niasahoid.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Knight made of Ink",
            "frTitle" : "Chevalier fait d'encre",
            "enText" : "Whenever you shield a card, you may banish a card you control not named Knight made of Ink, then return it to the field under its owner's control. This effect triggers only once each turn.",
            "frText" : "À chaque fois que vous protégez une carte, vous pouvez exiler une carte que vous contrôlez ne s'appellant pas Chevalier fait d'encre, puis la renvoyer sur le terrain sous le contrôle de son propriétaire. Cet effet ne se déclenche qu'une seule fois par tour. ",
            "image" : "knight_made_of_ink.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Knight made of Wood",
            "frTitle" : "Chevalier fait de bois",
            "enText" : "Whenever you shield a card, draw a card. This effect triggers only once each turn.",
            "frText" : "À chaque fois que vous protégez une carte, piochez une carte. Cet effet ne se déclenche qu'une seule fois par tour. ",
            "image" : "knight_made_of_wood.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Pwakomona",
            "frTitle" : "Pwakomona",
            "enText" : "",
            "frText" : "",
            "image" : "pwakomona.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Court Staff",
            "frTitle" : "Bâton de tribunal",
            "enText" : "*Base attack values of cards your opponent controls are set to 1 until your next opening phase.",
            "frText" : "*La valeur d'attaque de base des cartes que votre adversaire contrôle est fixée à 1 jusqu'à votre prochaine phase d'ouverture.",
            "image" : "court_staff.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Endothermic Effigy",
            "frTitle" : "Effigie endothermique",
            "enText" : "When Endothermic Effigy enters the field, you may banish another target card until Endothermic Effigy leaves the field.",
            "frText" : "Quand l'Effigie endothermique arrive sur le terrain, vous pouvez exiler une autre carte ciblée jusqu'à ce que l'Effigie endothermique quitte le terrain.",
            "image" : "endothermic_effigy.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Rinamona",
            "frTitle" : "Rinamona",
            "enText" : "",
            "frText" : "",
            "image" : "rinamona.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Sun of Haborym",
            "frTitle" : "Soleil d'Haborym",
            "enText" : "Sun of Haborym can shield up to two targets simultaneously.",
            "frText" : "Le Soleil d'Haborym peut protéger jusqu'à deux cibles en même temps.",
            "image" : "sun_of_haborym.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Exothermic Effigy",
            "frTitle" : "Effigie exothermique",
            "enText" : "When Exothermic Effigy is destroyed, banish target card your opponent controls.",
            "frText" : "Quand l'Effigie exothermique est détruite depuis le terrain, exilez la carte ciblée que votre adversaire contrôle.",
            "image" : "exothermic_effigy.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Goldblink Statue",
            "frTitle" : "Statue à l'éclat doré",
            "enText" : "*Banish target card on the field until its controller's next opening phase.",
            "frText" : "*Exilez la carte ciblée sur le terrain jusqu'à la prochaine phase d'ouverture de son contrôleur.",
            "image" : "goldblink_statue.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Mastam, Aristocrat of the Marble Court",
            "frTitle" : "Mastam, aristocrate de la Cour de Marbre",
            "enText" : "Cards you control get +1 on their shield value. *Your opponent can't activate abilities until your next opening phase.",
            "frText" : "Les cartes que vous contrôlez gagnent +1 sur leur valeur de shield. *Votre adversaire ne peut pas activer de capacités jusqu'à votre prochaine phase d'ouverture.",
            "image" : "mastam_marble_court_aristocrat.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Shaitam, Aristocrat of the Marble Court",
            "frTitle" : "Shaitam, aristocrate de la Cour de Marbre",
            "enText" : "Cards you control get +1 on their shield value. *Your opponent can't fuel their Altar from the field until your next opening phase.",
            "frText" : "Les cartes que vous contrôlez gagnent +1 sur leur valeur de shield. *Votre adversaire ne peut pas alimenter son Altar depuis le terrain jusqu'à votre prochaine phase d'ouverture.",
            "image" : "shaitam_marble_court_aristocrat.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Mormaona",
            "frTitle" : "Mormaona",
            "enText" : "",
            "frText" : "",
            "image" : "mormaona.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "O-Yama, King in Whispers",
            "frTitle" : "O-Yama, roi aux murmures",
            "enText" : "At the beginning of your opponent's end phase, they get pressured by 7. This number is reduced by 1 for each activation they resolved during their turn.",
            "frText" : "Au début de la phase de fin de votre adversaire, ce dernier subit 7 points de pression. Ce nombre est réduit de 1 pour chaque activation résolue par votre adversaire durant ce tour.",
            "image" : "o-yama_king_in_whispers.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Sigon, Primal Pulse Heterodine",
            "frTitle" : "Sigon, hétérodine de la Pulse primaire",
            "enText" : "Whenever your opponent resolves their second activation during their turn, you may put an Order card from your hand onto the field.",
            "frText" : "À chaque fois que votre adversaire résoud sa seconde activation durant son tour, vous pouvez mettre sur le terrain une carte Ordre de votre main.",
            "image" : "sigon_primal_pulse_heterodine.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Tan'mo, Tyrant of the Marble Court",
            "frTitle" : "Tan'mo, tyran de la Cour de Marbre",
            "enText" : "At the beginning of each of your opponent's main phase, cards they control must attack if able.",
            "frText" : "Au début de chacune des phases principales de votre adversaire, les cartes qu'il contrôle doivent attaquer si possible.",
            "image" : "tan'mo_tyrant_of_the_marble_court.png",
            "pulse": "order",
            "hasChange": false
        },{
            "enTitle" : "Rukiaona",
            "frTitle" : "Rukiaona",
            "enText" : "",
            "frText" : "",
            "image" : "rukiaona.png",
            "pulse": "order",
            "hasChange": false
        }
    // ORDER
]   

const template2 = {
    "enTitle" : "",
    "frTitle" : "",
    "enText" : "",
    "frText" : "",
    "image" : "",
    "pulse": "order",
    "hasChange": false
}


export default cardsInfos;
