/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the website
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';


//css
import './index.css';


//cards
import Cards from './cards.js'

const root = ReactDOM.createRoot(
    document.getElementById('root')
);





const element = (
    <div>
        <Cards />
    </div>
);
root.render(element);
*/
import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {createRoot} from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Cards from "./pages/cards";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="cards" element={<Cards />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const container = document.getElementById("app")
const root = createRoot(container);
root.render(<App />);

//ReactDOM.render(<App />, document.getElementById("root"));

  
