
/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




//cards
import Card from './card'

import cardsInfos from "./data"

import Pulse from "./pulse"


class Cards extends React.Component{

    constructor(props) {
        super(props)
    
        this.handler = this.handler.bind(this)
        
        this.state = {
            fullScreen: false
        }
      }
    
      handler(Card) {
        this.setState({
          fullScreen: !this.state.fullScreen,
          card: Card
        })
      }
   
    render(){


            var cardsContainer = []
            var truth = []
            var lies = []
            var order = []
            var chaos = []
            var life = []
            var death = []
            var i = 0
    
            cardsInfos.forEach(
                cardInfo => {
                    const img1 = require('../img/cards/'+cardInfo["image"])
                    var card = <Card
                        key = {i}
                        title={cardInfo["enTitle"]}
                        enText={cardInfo["enText"]}
                        frText={cardInfo["frText"]}
                        image={img1}
                        handler = {this.handler}
                    />
                    cardsContainer.push( 
                        card
                    )             
                    
                    switch (cardInfo["pulse"]){
                        case "truth" : truth.push(card); break;
                        case "lies" : lies.push(card); break;
                        case "order" : order.push(card); break;
                        case "chaos" : chaos.push(card); break;
                        case "life" : life.push(card); break;
                        case "death" : death.push(card); break;
                    }
                        
                    i = i+1;    
                }                    
            );

            var valReturn = []
            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>Preview Altarcg Cards <br/>TRUTH</Col>
                </Row>
            )

            for (let i = 0; i < truth.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{truth[i]}</Col>
                        <Col>{truth[i+1]}</Col>
                        <Col>{truth[i+2]}</Col>
                        <Col>{truth[i+3]}</Col>
                        <Col>{truth[i+4]}</Col>
                        <Col>{truth[i+5]}</Col>
                        
                    </Row>
                )
            }
            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>LIES</Col>
                </Row>
            )

            for (let i = 0; i < lies.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{lies[i]}</Col>
                        <Col>{lies[i+1]}</Col>
                        <Col>{lies[i+2]}</Col>
                        <Col>{lies[i+3]}</Col>
                        <Col>{lies[i+4]}</Col>
                        <Col>{lies[i+5]}</Col>
                        
                    </Row>
                )
            }

            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>ORDER</Col>
                </Row>
            )

            for (let i = 0; i < order.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{order[i]}</Col>
                        <Col>{order[i+1]}</Col>
                        <Col>{order[i+2]}</Col>
                        <Col>{order[i+3]}</Col>
                        <Col>{order[i+4]}</Col>
                        <Col>{order[i+5]}</Col>
                        
                    </Row>
                )
            }

            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>CHAOS</Col>
                </Row>
            )

            for (let i = 0; i < chaos.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{chaos[i]}</Col>
                        <Col>{chaos[i+1]}</Col>
                        <Col>{chaos[i+2]}</Col>
                        <Col>{chaos[i+3]}</Col>
                        <Col>{chaos[i+4]}</Col>
                        <Col>{chaos[i+5]}</Col>
                        
                    </Row>
                )
            }


           
            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>LIFE</Col>
                </Row>
            )

            for (let i = 0; i < life.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{life[i]}</Col>
                        <Col>{life[i+1]}</Col>
                        <Col>{life[i+2]}</Col>
                        <Col>{life[i+3]}</Col>
                        <Col>{life[i+4]}</Col>
                        <Col>{life[i+5]}</Col>
                        
                    </Row>
                )
            }
            valReturn.push(
                <Row xs={12} md={12} lg={12}>
                    <Col className='justify-content-center' style={{"display":"flex"}}>DEATH</Col>
                </Row>
            )

            for (let i = 0; i < death.length; i+=6) {
                valReturn.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col>{death[i]}</Col>
                        <Col>{death[i+1]}</Col>
                        <Col>{death[i+2]}</Col>
                        <Col>{death[i+3]}</Col>
                        <Col>{death[i+4]}</Col>
                        <Col>{death[i+5]}</Col>
                        
                    </Row>
                )
            }





            if (this.state.fullScreen){
                const withText = <div>
                    <Container fluid className='fullScreen'>
                        <Row>
                            
                            <Col md = {1} lg={2}  ></Col>
                            <Col xs={6} md = {5} lg={3} className='imageText'>
                               <div className='imageTextwrap'>
                                {this.state.card.props.enText}
                                <br />
                                {this.state.card.props.frText}
                               </div>
                            </Col>
                            <Col xs={6} md = {4} lg={4} className="justify-content-center">{this.state.card.render()}</Col>
                            
                        </Row>
                        
                    </Container>
                    <Container fluid>
                        {valReturn}
                    </Container>
                    
                </div>
                const withoutText =  <div>
                <Container fluid className='fullScreen'>
                    <Row>
                        
                        <Col xs={12} md = {12} lg={12} className="justify-content-center">{this.state.card.render()}</Col>
                        
                    </Row>
                    
                </Container>
                <Container fluid>
                    {valReturn}
                </Container>
            
                </div>
                if (this.state.card.props.enText != ""){
                    return withText
                }else {
                    return withoutText
                }
                    
                        
                    
                    
                }
            
        return <Container fluid >
            <Pulse 
                pulse={truth}
                name="truth"
            />
             <Pulse 
                pulse={lies}
                name="lies"
            />
             <Pulse 
                pulse={order}
                name="order"
            />
             <Pulse 
                pulse={chaos}
                name="chaos"
            />
             <Pulse 
                pulse={life}
                name="life"
            />
             <Pulse 
                pulse={death}
                name="death"
            />
            
        </Container>
        
            
            
            
    }
}

export default Cards
