/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from 'react';
import Image from 'react-bootstrap/Image'


const discord = require('../img/icons/discord.png')
const facebook = require('../img/icons/facebook.png')
const instagram = require('../img/icons/instagram.png')
const twitch = require('../img/icons/twitch.png')
const youtube = require('../img/icons/youtube.png')
const twitter = require('../img/icons/twitter.png')
const linkedin = require('../img/icons/linkedin.png')

class Social extends React.Component{
    render(){
        return <section
            className="d-flex justify-content-between p-4 text-white"
            style ={{"background-color" : "#21D192"}}
        >
            
            <div className="me-5 d-none d-lg-block">
                <span>Suivez nous sur les réseaux:</span>
            </div>
            
            <div>
                
                    <a href="https://discord.gg/hhqTtxfahv" target="_blank" >
                    <i> <Image style={{"width" : "auto", "height": "40px"}} src={discord} /></i>
                    </a>
                
                    <a href="https://www.facebook.com/abalance.ch" target="_blank" >
                    <i> <Image style={{"width" : "auto", "height" : "40px"}} src={facebook} /></i>
                    </a>
                
                    <a href="https://www.instagram.com/abalance_gaming/?hl=fr%2F" target="_blank" >
                    <i> <Image style={{"width" : "auto", "height": "40px"}} src={instagram} /></i>
                    </a>
                

                    <a href="https://www.twitch.tv/wentai_" target="_blank" >
                    <i> <Image style={{"width": "auto", "height": "40px"}} src={twitch} /></i>
                    </a>

                    <a href="https://www.youtube.com/channel/UCrQiCzy-DE86cel3P1VnY-Q/featured" target="_blank" >
                    <i> <Image style={{"width": "auto", "height": "40px"}} src={youtube} /></i>
                    </a>

                    <a href="https://twitter.com/Abalance16/" target="_blank" >
                    <i> <Image style={{"width": "auto", "height": "40px"}} src={twitter} /></i>
                    </a>

                    <a href="https://www.linkedin.com/company/abalance-sa/" target="_blank" >
                    <i> <Image style={{"width": "auto", "height": "40px"}} src={linkedin} /></i>
                    </a>
            </div>


           
           
        </section>
    }
}



export default Social