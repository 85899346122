/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from 'react';

class Introduction extends React.Component{
     render(){
        return <div className="Introduction">
            <h1> Introduction </h1>
            <p className="text-center">
                Altar est un jeu de cartes à jouer et collectionner en cours de développement prenant place dans un univers fantastique. Dans Altar, vous affrontez un.e autre joueur.se à l’aide d’un paquet de cartes sélectionnées par vos soins en vue d’obtenir les synergies les plus dévastatrices. Vous devrez anticiper la stratégie adverse, séquencer intelligemment vos actions et combiner au mieux vos cartes pour espérer l’emporter !
                <br />
                <a href = "https://docs.google.com/document/d/1-51AabhZIUlR3iiFk3uXHgssK25Ir0grRBvwERM8ZV4/edit" target="_blank"> Règles du jeu </a>
            </p>
        </div>

    }
}

export default Introduction