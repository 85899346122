/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Image from 'react-bootstrap/Image'
import Lightbox from "react-modal-image";




class Card extends React.Component{

    constructor(props) {    
        super(props)
            
        this.state = {
            isOpen: false 
        }
    }
    

    handleShowDialog = () => {
        this.state.isOpen =  !this.state.isOpen
        this.props.handler(this)
    };

   
    render(){
        var valReturn = !this.state.isOpen ? <Image 
                fluid
                className="card-image"
                src={this.props.image}
                onClick={this.handleShowDialog}
                //onClick = {this.props.handler}
            /> : <Image 
                
                className="popup"
                src={this.props.image}
                onClick={this.handleShowDialog}
                alt="no image"
            />
            
          
           
                /*
            
            <dialog
                className="popup"
                /*style={{ position: "absolute" }}
                open
                onClick={this.handleShowDialog}
                >
                */
                
            //</dialog>
            
            
       
        
       
        return valReturn
        
    



    }
}

  

export default Card;
